import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Header } from '../components/header';

import * as stylesLayout from './index.module.css';
import * as stylesExpressionCorporelle from './expression-corporelle.module.css';
import imageExpressionCorpFemme from '../images/expression-corporelle-femme.jpg';
import imageExpressionCorpPano from '../images/expression_corporelle_pano_1.jpg';
import imageExpressionCorpEnfants from '../images/expression-corporelle-enfants.jpg';

const Relaxation = ({ data }) => {
  return (
    <div className={`${stylesLayout.main}`}>
      <div>
        <Helmet>
          <meta name="description" content="Expression corporelle. Détente. Relaction." />
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
        <Header></Header>
      </div>
      <div className={stylesLayout.mainContent}>
        <h1>L’expression corporelle pour bouger son corps en se relaxant</h1>
        <img src={imageExpressionCorpFemme} alt="" className={stylesExpressionCorporelle.img} />
        <p className={stylesExpressionCorporelle.quote}>Venez reprendre pleinement conscience de votre corps.</p>

        <p className={stylesExpressionCorporelle.textIntro}>
          La séance se pratique en groupe. L’expression corporelle peut se pratiquer à tout âge.
          <br />
          Par des techniques de danse et de relaxation, venez évoluer sur de la musique du monde.
        </p>
        <img src={imageExpressionCorpPano} alt="" className={stylesExpressionCorporelle.img} />

        <p className={stylesExpressionCorporelle.textIntro}>
          La séance débute par la prise de contact avec son corps, par des{' '}
          <strong>techniques de respiration abdominale et un échauffement</strong> de toutes les parties mobiles du
          corps.
          <br />
          Puis, mise en place d’exercices pour <strong>bouger son corps sur de la musique</strong>, trouver le rythme et
          évoluer dans l’espace, au sein d’un groupe de personnes ou dans votre bulle de bien-être.
          <br />
          La séance se termine par des <strong>étirements et de la relaxation sur une musique douce</strong>.
          <br />
          Du matériel peut être utilisé pour accompagner l’imaginaire créatif des participants (tissu, balle, anneau,
          etc…).
        </p>
        <img src={imageExpressionCorpEnfants} alt="" className={stylesExpressionCorporelle.img} />
        <p>
          L’essentiel est de venir :
          <ul>
            <li>sans contraintes</li>
            <li>dans l’esprit de prendre soin de soi</li>
            <li>dans le respect de l’autre</li>
            <li>pour se (re)découvrir en s’amusant</li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
export default Relaxation;
